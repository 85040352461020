import { Navigation } from 'components';

import styles from './home-page.module.scss';

import logo from '../../components/logo/logo.svg';

export default function HomePage() {
  return (
    <div className={styles.page}>
      <div>
        <img src={logo} className={styles.logo} alt='logo' />
      </div>
      <Navigation />
    </div>
  )
}
