import axios from 'axios';
import { getTelegramUserId } from 'helpers/telegram-helper';

const axiosInstance = axios.create({
  baseURL: getApiBaseUrl(),
  headers: {
    'Authorization': getApiToken(),
  },
  params: {
    telegram_user_id: getTelegramUserId(),
  },
});

const api = {
  findServicesByName(serviceName) {
    return axiosInstance.get('/services', {
      params: {
        title: serviceName,
      },
    });
  },
  getServicesById(serviceId) {
    return axiosInstance.get('/get_service_by_id', {
      params: {
        service_id: serviceId,
      },
    });
  },
  addServiceToSelected(serviceId) {
    return axiosInstance.post('/add_to_selected', {}, {
      params: {
        service_id: serviceId,
      },
    });
  },
  removeServiceToSelected(serviceId) {
    return axiosInstance.delete('/remove_from_selected', {
      params: {
        service_id: serviceId,
      },
    });
  },
  getSelectedServices() {
    return axiosInstance.get('/get_selected_services');
  },
  sendForm(formId, formData) {
    return axiosInstance.post(`/${formId}/feedback`, formData, {
      baseURL: getContactForm7ApiUrl(),
    });
  },
  sendServiceForm({formId, formData}) {
    return axiosInstance.post(`/${formId}/feedback`, formData, {
      baseURL: getContactForm7ApiUrl(),
    });
  },
  sendMessageToUserChat(telegram_user_id, message) {
    return axiosInstance.post('/sendMessage', {
      chat_id: telegram_user_id,
      text: message,
    }, {
      baseURL: `https://api.telegram.org/bot${getTelegramToken()}`,

    });
  },
  sendServiceToUserChat(serviceId) {
    return axiosInstance.post('/send_service_to_user_chat', {}, {
      params: {
        service_id: serviceId,
      },
    });
  },
};

export function getApiBaseUrl() {
  if (!process.env.REACT_APP_API_BASE_URL) throw new Error('REACT_APP_API_BASE_URL env variable not defined');

  return process.env.REACT_APP_API_BASE_URL;
}

export function getContactForm7ApiUrl() {
  if (!process.env.REACT_APP_CONTACT_FORM_7_API_URL) throw new Error('REACT_APP_CONTACT_FORM_7_API_URL env variable not defined');

  return process.env.REACT_APP_CONTACT_FORM_7_API_URL;
}

export function getApiToken() {
  if (!process.env.REACT_APP_API_TOKEN) throw new Error('REACT_APP_API_TOKEN env variable not defined');

  return `Bearer ${process.env.REACT_APP_API_TOKEN}`;
}

export function getTelegramToken() {
  if (!process.env.REACT_APP_TELEGRAM_BOT_TOKEN) throw new Error('REACT_APP_TELEGRAM_BOT_TOKEN env variable not defined');

  return process.env.REACT_APP_TELEGRAM_BOT_TOKEN;
}

export default api;
