import { LoadingIcon } from 'components/icons';
import PropTypes from 'prop-types';

import styles from './search.module.scss';

export default function Search({ loading, onSearch, value }) {
  return (
    <div>
      <label className={styles.label}>
        <span className={styles.text}>Введите название медицинской услуги</span>

        <div className={styles.fieldWrapper}>
          <input
            type='text'
            placeholder='Название услуги'
            className={styles.field}
            onChange={onSearch}
            value={value}
          />

          {loading && <LoadingIcon className={styles.loadingIcon} />}
        </div>
      </label>
    </div>
  )
}

Search.defaultProps = {
  loading: false,
  onSearch: () => {},
  value: '',
};

Search.propTypes = {
  loading: PropTypes.bool,
  onSearch: PropTypes.func.isRequired,
  value: PropTypes.string,
};
