import { LoadingIcon } from 'components/icons';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getSelectedServices } from 'store/slices/searchSlice';

import styles from './navigation.module.scss';

export default function Navigation() {
  const {
    selectedItems,
    selectedItemsFetchingLoading,
  } = useSelector(state => state.search);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSelectedServices());
  }, [dispatch]);

  return (
    <nav>
      <ul className={styles.items}>
        <li className={styles.item}>
          <Link to="/search">Поиск услуг</Link>
        </li>

        <li className={styles.item}>
          <Link to="/selected">
            {
              selectedItems.length
              ? `Выбранные услуги (${selectedItems.length})`
              : 'Выбранные услуги'
            }
            {
              selectedItemsFetchingLoading
              ? <LoadingIcon size={20} color="#fff" />
              : ''
            }
          </Link>
        </li>

        <li className={styles.item}>
          <Link to="/feedback">Обратная связь</Link>
        </li>
      </ul>
    </nav>
  )
}
