import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { HomePage, SearchPage, SelectedServicesPage, FeedbackPage, ServicePage, SelectedConsultationPage } from 'pages';

import { store } from 'store';
import { ErrorBoundary } from 'components';

export default function App() {
  useEffect(() => {
    window.Telegram?.WebApp?.ready();
  }, []);

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <Router>
          <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/search' element={<SearchPage />} />
            <Route path='/selected' element={<SelectedServicesPage />} />
            <Route path='/feedback' element={<FeedbackPage />} />
            <Route path='/service/:serviceId' element={<ServicePage />} />
            <Route path='/selected-consultation' element={<SelectedConsultationPage />} />
            <Route path='*' element={<div>not found</div>} />
          </Routes>
        </Router>
      </Provider>
    </ErrorBoundary>
  )
}
