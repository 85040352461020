import { Component } from 'react';

import styles from './error-boundary.module.scss';

export default class ErrorBoundary extends Component {
  state = {
    hasError: false,
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.error}>Что-то пошло не так. Попробуйте ещё раз позднее.</div>
      );
    }

    return this.props.children;
  }
}
