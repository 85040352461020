export function getFeedbackFormId() {
  if (!process.env.REACT_APP_FEEDBACK_FORM_ID) throw new Error('REACT_APP_FEEDBACK_FORM_ID env variable not defined');

  return process.env.REACT_APP_FEEDBACK_FORM_ID;
}

export function getConsultationFormId() {
  if (!process.env.REACT_APP_CONSULTATION_FORM_ID) throw new Error('REACT_APP_CONSULTATION_FORM_ID env variable not defined');

  return process.env.REACT_APP_CONSULTATION_FORM_ID;
}

export function getPrivacyPolicyLink() {
  if (!process.env.REACT_APP_PRIVACY_POLICY_LINK) throw new Error('REACT_APP_PRIVACY_POLICY_LINK env variable not defined');

  return process.env.REACT_APP_PRIVACY_POLICY_LINK;
}

export function getSelectedServicesFormId() {
  if (!process.env.REACT_APP_SELECTED_SERVICES_FORM_ID) throw new Error('REACT_APP_SELECTED_SERVICES_FORM_ID env variable not defined');

  return process.env.REACT_APP_SELECTED_SERVICES_FORM_ID;
}
