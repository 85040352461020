import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Search } from 'components';
import { ServiceList } from 'components/services';

import {
  updateText,
  getServicesByName,
  addServiceToSelected,
  removeServiceFromSelected,
  sendServiceToClientChat,
  getSelectedServices,
} from 'store/slices/searchSlice';

import styles from './search-page.module.scss';

export default function SearchPage() {
  const {
    text,
    foundItems,
    loading,
    error,
    sendServiceError,
    serviceSent,
    selectedItems,
  } = useSelector(state => state.search);
  const dispatch = useDispatch();

  useEffect(() => {
    if (text) {
      dispatch(getServicesByName(text));
    }
  }, [dispatch, text]);

  useEffect(() => {
    dispatch(getSelectedServices());
  }, [dispatch]);

  useEffect(() => {
    if (serviceSent) {
      window.Telegram.WebApp.close();
    }
  }, [serviceSent]);

  return (
    <div className={styles.page}>
      <Search
        value={text}
        loading={loading}
        onSearch={({ target }) => dispatch(updateText(target.value))}
      />

      {error && <div className={styles.error}>{error}</div>}

      {sendServiceError && <div className={styles.error}>{sendServiceError}</div>}

      {foundItems.length
        ? (
          <div className={styles.foundItems}>
            <h3 className={styles.subtitle}>Найденные услуги</h3>

            <ServiceList
              items={foundItems}
              onInfo={(id) => dispatch(sendServiceToClientChat(id))}
              onAdd={(id) => dispatch(addServiceToSelected(id))}
              onRemove={(id) => dispatch(removeServiceFromSelected(id))}
            />
          </div>
        )
        : null
      }

      <div className={styles.footer}>
        <div className={styles.footerButton}>
          <Link to='/' className={styles.backButton}>Назад</Link>
        </div>

        <Link to='/selected' className={styles.selectedCounter}>
          {
            selectedItems.length > 0
            ? <span>Выбранно услуг: {selectedItems.length}</span>
            : <span>Выбранных услуг нет</span>
          }
        </Link>
      </div>
    </div>
  )
}
