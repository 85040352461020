import PropTypes from 'prop-types';

import { Service } from 'components/services';

import styles from './service-list.module.scss';

export default function ServiceList({
  items,
  onInfo,
  onAdd,
  onRemove
}) {
  return (
    <div className={styles.serviceList}>
      {items.map(({
        id,
        title,
        price,
        selected,
        loading,
        sending,
      }) => (
        <Service
          key={id}
          id={id}
          title={title}
          price={price}
          selected={selected}
          loading={loading}
          sending={sending}
          onInfo={onInfo}
          onAdd={onAdd}
          onRemove={onRemove}
        />
      ))}
    </div>
  )
}

ServiceList.defaultProps = {
  items: [],
}

ServiceList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      price: PropTypes.string.isRequired,
      selected: PropTypes.bool,
      loading: PropTypes.bool,
      sending: PropTypes.bool,
    }),
  ),
  onInfo: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};
