import PropTypes from 'prop-types';

export default function AddIcon({ className, size }) {
  return (
    <div className={className}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 24 24'
        width={size}
        height={size}
      >
        <path d='M17,13H13V17H11V13H7V11H11V7H13V11H17M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z' />
      </svg>
    </div>
  )
}

AddIcon.defaultProps = {
  size: 24,
  className: '',
};

AddIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
};
