import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { LoadingIcon } from 'components/icons';
import { ServiceList } from 'components/services';

import { getSelectedServices, removeServiceFromSelectedList, sendServiceFromSelectedToClientChat } from 'store/slices/searchSlice';

import styles from './selected-services-page.module.scss';
import { BackButton } from 'components';

export default function SelectedServicesPage() {
  const {
    serviceSent,
    selectedItems,
    selectedItemsFetchingLoading,
    selectedItemsFetchingError,
  } = useSelector(state => state.search);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSelectedServices());
  }, [dispatch]);

  useEffect(() => {
    if (serviceSent) {
      window.Telegram.WebApp.close();
    }
  }, [serviceSent]);

  return (
    <div className={styles.page}>
      <h1 className={styles.title}>Выбранные услуги</h1>

      {selectedItemsFetchingError && <div className={styles.error}>{selectedItemsFetchingError}</div>}

      {
        selectedItemsFetchingLoading
          ? (
              <div className={styles.selectedServicesLoading}>
                <div>Загрузка списка выбранных услуг</div>
                <LoadingIcon className={styles.loadingIcon} />
              </div>
            )
          : selectedItems.length
              ? (
                <ServiceList
                  items={selectedItems}
                  onInfo={(id) => dispatch(sendServiceFromSelectedToClientChat(id))}
                  onAdd={() => {}}
                  onRemove={(id) => dispatch(removeServiceFromSelectedList(id))}
                />
              )
              : (
                <div>У вас пока нет выбранных услуг.<br />Поищите услуги в разделе "Поиск услуг"</div>
              )
        }

        {
          selectedItems.length && !selectedItemsFetchingLoading && !selectedItemsFetchingError
            ? (
                <div className={styles.total}>
                  <span>Общая стоимость:</span> {selectedItems.reduce((acc, {price}) => acc + +price, 0)} руб.
                </div>
              )
            : null
        }

        {
          selectedItems.length && !selectedItemsFetchingLoading && !selectedItemsFetchingError
            ? (
              <div className={styles.footer}>
                <div className={styles.footerButton}>
                  <Link to='/' className={styles.backButton}>Назад</Link>
                </div>

                <Link to='/selected-consultation' className={styles.selectedCounter}>Получить консультацию</Link>
              </div>
            )
            : <BackButton text='Назад' />
        }
    </div>
  )
}
