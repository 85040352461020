import { useNavigate } from 'react-router-dom';

import styles from './back-button.module.scss';

export default function BackButton({text}) {
  const navigate = useNavigate()
  const toBack = () => navigate('/')

  return (
    <button
      type='button'
      className={styles.button}
      onClick={toBack}
    >
      {text}
    </button>
  )
}
