import PropTypes from 'prop-types';
import classNames from 'classnames';

import { AddIcon, InfoIcon, LoadingIcon, RemoveIcon } from 'components/icons';

import styles from './service.module.scss';

export default function Service({
  id,
  title,
  price,
  selected,
  loading,
  sending,
  onInfo,
  onAdd,
  onRemove
}) {
  return (
    <div className={styles.service}>
      <div>
        <div className={styles.title}>{title}</div>
        <div className={styles.price}><span>Стоимость:</span> {price} руб.</div>
      </div>

      <div className={styles.actions}>
        <button
          type='button'
          className={styles.button}
          disabled={sending}
          onClick={() => onInfo(id)}
        >
          {
            sending
              ? <LoadingIcon className={styles.loadingIcon} />
              : <InfoIcon className={styles.icon} />
          }
        </button>

        <button
          type='button'
          className={classNames(styles.button, {
            [styles.buttonRed]: selected,
            [styles.buttonGreen]: !selected,
          })}
          disabled={loading}
          onClick={() => selected ? onRemove(id) : onAdd(id)}
        >
          {
          loading
            ? <LoadingIcon className={styles.loadingIcon} />
            : selected
              ? <RemoveIcon className={styles.icon} />
              : <AddIcon className={styles.icon} />
          }
        </button>
      </div>
    </div>
  )
}

Service.defaultProps = {
  selected: false,
  loading: false,
  sending: false,
};

Service.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  loading: PropTypes.bool,
  sending: PropTypes.bool,
  onInfo: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};
